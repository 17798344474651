import React, { useEffect, useRef, useState } from 'react';
import { Grid, TextField, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { colors } from 'utils/styles';
import Input from 'components/FormUI/Input';
import MaskedInput from 'components/FormUI/MaskedInput';
import { LabelText } from 'components/UI/Text/TextStyles';
import { StyledLink } from 'pages/Auth/SignInPage';
import styled from 'styled-components';
import Select from '@material-ui/core/Select';
import SignatureCanvas from 'react-signature-canvas';
import * as countryActions from 'actions/country';
import { PhoneInput } from 'react-international-phone';
import Checkbox from '@material-ui/core/Checkbox';
import { useSelector } from 'react-redux';
import {
  ACCOUNT_FORM_HEADERS,
  ACCOUNT_FORM_FIELDS,
  RESTORE_PASS,
  PURCHASE_MODAL_STEPS,
} from '../PurchaseModal.constants';
import * as timeZoneActions from 'actions/timeZone';
import { getOTP } from 'actions/user';
import './CustomAccountForm.scss';
import { TemplateType } from 'helpers/constants/templateType';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import {
  useAccount,
  useApplicationStatus,
  useClientPreview,
  useDefaultTimezone,
  useRouter,
  useScreenView,
} from 'hooks';

import { getThemedColors } from 'services/contributions.service';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import { ContributionType } from 'helpers/constants';
import { isEmpty } from 'lodash';
import { useStyles } from '../../PurchaseBlock/hooks/useStyles';
import { PAYMENT_OPTIONS } from '../../../../../constants';
import { getPaymentTypeForUi } from 'utils/utils';
import { Link } from 'react-router-dom';
import SignInWithOtpModal from 'components/Modals/SignInWithOtpModal';

const StyledPhoneInput = styled(PhoneInput)`
  width: ${({ mobileView }) => (mobileView ? '100%' : '60%')};
  gap: 10px;
  &.phone-error {
    .react-international-phone-input {
      border-color: red;
    }
  }
  .react-international-phone-country-selector {
    .react-international-phone-country-selector-button {
      padding: 10px 6px;
      height: auto;
      border-radius: 10px;
    }
  }
  .react-international-phone-input {
    width: 100%;
    padding: 10px 10px;
    height: auto;
    border-radius: 10px;
    font-size: 14px;
  }
`;

const HelperTextField = styled(TextField)`
  background-color: ${({ isDarkModeEnabled }) =>
    isDarkModeEnabled ? 'rgb(37, 39, 40) !important' : '#fff !important'};
  .MuiFormHelperText-root {
    color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff !important' : 'rgba(0, 0, 0, 0.87) !important')};
  },
`;

const StyledError = styled.div`
  color: ${({ template, PrimaryColor }) =>
    template === TemplateType.TemplateOne
      ? PrimaryColor
        ? PrimaryColor
        : '#6999CA'
      : PrimaryColor
      ? PrimaryColor
      : '#D08ACD'};
  font-size: 14px;
  margin-top: 2px;
  padding-left: 10px;
`;

const StyledEmail = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 5px;
  font-size: 13px;
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#2b2b2b')};
`;

const StyledRemoveBtnContainer = styled.div`
  cursor: pointer;
`;

const StyledHeaderText = styled(LabelText)`
  color: ${({ color }) => color};
`;
export const SendOtpLink = styled(Link)`
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#2b2b2b')};
  font-family: 'Avenir';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 1.17;
  margin-left: 5px;
  margin-top: 14px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 360px) {
    font-size: 12px;
  }
  @media (max-width: 390px) {
    font-size: 14px;
  }
  @media (max-width: 375px) {
    font-size: 12px;
  }
`;
export const SendOtpLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
function CustomAccountForm({
  handleChange,
  setFieldValue,
  handleBlur,
  activeTemplate,
  isElectronicSignatureActive,
  formRef,
  setDisableValue,
  colorToUse,
  values,
  touched,
  errors,
  step,
  setStepValue,
  timeZoneLoading,
  timeZones,
  fetchTimeZones,
  selectedTimeZone,
  onClose,
  typeOfPayment,
  isMonthlySessionSubscription,
  setSmsReminderCheckBox,
  smsReminderCheckBox,
  setshowPhoneNumberFields,
  showPhoneNumberFields,
  setloginStep,
  loginStep,
  setPhoneNumberField,
  phoneNumberField,
  showErrorPhoneNumber,
}) {
  const contribution = useContribution();
  const { clientPreviewMode } = useClientPreview();
  const dispatch = useDispatch();
  const { user } = useAccount();
  const { query, history } = useRouter();
  const queryCode = query?.code === undefined ? null : query?.code?.includes('p_') ? null : query?.code;

  const { newThemedTextColor, newThemedBackgroundColor } = getThemedColors(contribution);
  const { applicationRequiredButNotApproved, isApplicationNeedsToBeSubmitted } = useApplicationStatus({
    contribution,
    user,
  });
  const { countries } = useSelector(state => state?.country);
  const { states } = useSelector(state => state?.states);
  const [timeZoneArray, setTimeZoneArray] = useState([]);
  const [statesArray, setStatesArray] = useState([]);
  const [coachPhoneNumber, setCoachPhoneNumber] = useState('');
  const [defaultCountry, setDefaultCountry] = useState();
  const [coachPhoneNumberFocused, setCoachPhoneNumberFocused] = useState(false);
  const [colorForSelect, setColorForSelect] = useState('darkgrey');
  const [showOtpModal, setShowOtpModal] = useState(false);
  const isCodeToJoin = useSelector(state => state?.contributions?.singleCode);
  const isCodeToJoinNotNull = isCodeToJoin != null;
  const { error } = useSelector(state => state.account);
  const { domain } = useRouter();
  const { paymentInfo } = useContribution();
  const { isAccountNeeded } = contribution;
  const isPackage = typeOfPayment?.includes('Package');
  const easyBookingData = useSelector(state => state?.easyBooking);
  const { fetchUserTimezone, fetchUserCountryCode } = useDefaultTimezone();

  const paymentType = getPaymentTypeForUi(paymentInfo, query, queryCode);

  const getIsGuestUserFlow = () => {
    return !history.location.pathname.includes('s_') && !isAccountNeeded && paymentType === 'FREE';
  };

  const setTimezone = async () => {
    const finalTimezone = await fetchUserTimezone(user?.timeZoneId, contribution?.timeZoneId, timeZones);
    setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, finalTimezone);
  };

  useEffect(() => {
    setTimeZoneArray(timeZones);
    if (selectedTimeZone === easyBookingData?.timeZone) {
      setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, selectedTimeZone);
    }
    if (easyBookingData?.timeZone && selectedTimeZone !== easyBookingData?.timeZone) {
      setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, easyBookingData.timeZone);
    }
    if (!selectedTimeZone && contribution) {
      setTimezone();
    }
  }, [timeZones, contribution]);

  const setDefaultCountryCode = async () => {
    const countryCode = await fetchUserCountryCode(formRef?.current?.values?.TimeZoneId, timeZones, countries);
    setDefaultCountry(countryCode);
  };

  useEffect(() => {
    if (timeZones?.length > 0 && countries?.length > 0) setDefaultCountryCode();
  }, [formRef?.current?.values?.TimeZoneId, timeZones, countries]);

  const isShowInput = (step, input) => {
    const visible = {
      init: [ACCOUNT_FORM_FIELDS.email],
      join: [ACCOUNT_FORM_FIELDS.email],
      loggedIn: [ACCOUNT_FORM_FIELDS.sessionReminder],
      joinLogin: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.signature,
        RESTORE_PASS,
        ACCOUNT_FORM_FIELDS.timeZoneId,
        ACCOUNT_FORM_FIELDS.sessionReminder,
      ],
      login: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.signature,
        RESTORE_PASS,
        ACCOUNT_FORM_FIELDS.timeZoneId,
      ],
      joinCreate: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.confirmEmail,
        // ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.firstName,
        ACCOUNT_FORM_FIELDS.lastName,
        ACCOUNT_FORM_FIELDS.timeZoneId,
        ACCOUNT_FORM_FIELDS.country,
        ACCOUNT_FORM_FIELDS.state,
        ACCOUNT_FORM_FIELDS.signature,
        ACCOUNT_FORM_FIELDS.sessionReminder,
        ACCOUNT_FORM_FIELDS.phone,
      ],
      create: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.confirmEmail,
        // ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.firstName,
        ACCOUNT_FORM_FIELDS.lastName,
        ACCOUNT_FORM_FIELDS.timeZoneId,
        ACCOUNT_FORM_FIELDS.country,
        ACCOUNT_FORM_FIELDS.state,
        ACCOUNT_FORM_FIELDS.signature,
        ACCOUNT_FORM_FIELDS.sessionReminder,
        ACCOUNT_FORM_FIELDS.phone,
      ],
      joinEnrollmentForm: [],
      enrollmentForm: [],
    };
    return visible[step].includes(input);
  };
  const classNames = useStyles();
  const canvasRef = useRef();
  const phoneInputRef = useRef(null);
  const textFieldRef = useRef(null);
  const PrimaryColor = colorToUse?.PrimaryColorCode;
  const confirmEmailRef = useRef(null);
  const emailRef = useRef(null);
  const isInviteToJoin = useSelector(state => state.contributions.inviteCode);
  const { mobileView } = useScreenView();
  const clearSign = () => {
    if (canvasRef?.current && formRef?.current) {
      canvasRef.current.clear();
      // setDisableBtn(true);
      setDisableValue(true);
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.signature, null);
    }
  };

  useEffect(() => {
    const disablePaste = e => {
      e.preventDefault();
    };

    if ((!timeZones || timeZones.length === 0) && !timeZoneLoading) {
      fetchTimeZones();
    }
    if (!countries || !countries.length) {
      dispatch(countryActions.fetchCountries());
    }
    if (!states || !states.length) {
      dispatch(countryActions.fetchStates());
    }
    const confirmEmailInput = confirmEmailRef.current;
    if (!confirmEmailInput) return;

    confirmEmailInput.addEventListener('paste', disablePaste);
    return () => {
      confirmEmailInput.removeEventListener('paste', disablePaste);
    };
  }, [step, timeZones]);

  useEffect(() => {
    if (phoneInputRef.current && !phoneNumberField) {
      const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
      if (phoneInput) {
        phoneInput.addEventListener('focus', () => {
          setCoachPhoneNumberFocused(true);
        });
      }
      return () => {
        if (phoneInput) {
          phoneInput.removeEventListener('focus', () => {
            setCoachPhoneNumberFocused(false);
            setCoachPhoneNumber('');
          });
        }
      };
    }
    if (phoneNumberField) {
      setCoachPhoneNumberFocused(true);
      setCoachPhoneNumber(phoneNumberField);
    }
  }, [phoneInputRef.current, showPhoneNumberFields, smsReminderCheckBox, step]);

  useEffect(() => {
    if (coachPhoneNumberFocused && phoneInputRef.current) {
      const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
      if (phoneInput) {
        phoneInput.focus();
      }
    }
  }, [coachPhoneNumberFocused]);

  useEffect(() => {
    if (user?.phoneNo?.length > 0 && !isEmpty(user)) {
      setPhoneNumberField(user?.phoneNo);
    }
  }, [user]);

  useEffect(() => {
    if (emailRef.current && step !== PURCHASE_MODAL_STEPS.init) {
      emailRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [step, timeZones, fetchTimeZones]);

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [textFieldRef.current, phoneInputRef.current, showPhoneNumberFields, smsReminderCheckBox, step]);

  const handlePaste = e => {
    e.preventDefault();
  };

  const useSelectStyle = makeStyles(() => ({
    select: {
      width: '100%',
      color: colorForSelect,
      backgroundColor: 'white',
      borderRadius: '20px',
      border: '1px solid #f5f0f0',
      height: '53px',
    },
    handleNewFormFieldsLastName: {
      margin: '-10px 0px 0px 0px',
      marginTop: '5px',
    },
    handleNewFormFieldsTimeZone: {
      margin: ' -10px 0px 0px 0px',
      marginTop: '5px',
    },
  }));

  const selectStyle = useSelectStyle();

  const EmailVal = values && values[ACCOUNT_FORM_FIELDS.email];
  const isSessionPage = window?.location?.pathname?.includes('/sessions');

  const agrementText =
    isElectronicSignatureActive && isShowInput(step, ACCOUNT_FORM_FIELDS.signature)
      ? 'signing and clicking'
      : 'clicking';

  const closeModal = () => setShowOtpModal(false);
  const handleShowOtpPopup = () => {
    setShowOtpModal(true);
    const email = formRef?.current?.values[ACCOUNT_FORM_FIELDS.email].trim();
    getOTP(email)(dispatch);
  };
  return (
    <Grid item container xs={12} className={getIsGuestUserFlow() ? 'mb-3' : 'my-3'} style={{ padding: '5px 30px' }}>
      {isInviteToJoin && (
        <div
          className="single-session-back-button free-booking"
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            color: colorToUse?.PrimaryColorCode,
          }}
        >
          <div style={{ cursor: 'pointer' }} onClick={onClose}>
            <b>{`<`}</b>
          </div>
        </div>
      )}
      <Grid item xs={12}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {(!getIsGuestUserFlow() || isPackage || isSessionPage) && (
            <StyledHeaderText color={colorToUse?.PrimaryColorCode}>
              {isSessionPage && contribution.type === ContributionType.contributionOneToOne
                ? ACCOUNT_FORM_HEADERS?.joinEasyBooking
                : ACCOUNT_FORM_HEADERS[step]}
            </StyledHeaderText>
          )}
        </div>
        {/* <br /> */}
      </Grid>
      {!applicationRequiredButNotApproved && (
        <Grid
          item
          // spacing={isCodeToJoin ? 0 : 1}
          style={
            isCodeToJoinNotNull
              ? {
                  // margin: '5px',
                  display: 'flex',
                  justifyContent: 'center',
                }
              : {
                  // margin: '5px'
                }
          }
          container
          xs={12}
        >
          <Grid
            item
            container
            // spacing={isCodeToJoin ? 0 : 1}
            sm={12}
            xs={12}
            style={isCodeToJoinNotNull ? { display: 'flex', justifyContent: 'center' } : {}}
          >
            {(clientPreviewMode || (step != PURCHASE_MODAL_STEPS.login && step != PURCHASE_MODAL_STEPS.joinLogin)) && (
              <>
                {(!getIsGuestUserFlow() || isPackage) && (
                  <Grid item xs={12}>
                    {/* <Input
                    fullWidth
                    name={ACCOUNT_FORM_FIELDS.email}
                    type="text"
                    label="Email"
                    error={touched.Email && errors.Email}
                    ref={emailRef}
                  /> */}
                    <TextField
                      fullWidth
                      className={classNames.inputField}
                      name={ACCOUNT_FORM_FIELDS.email}
                      placeholder={!getIsGuestUserFlow() ? 'Enter email' : 'Email'}
                      variant="outlined"
                      type="text"
                      onChange={e => {
                        setFieldValue(ACCOUNT_FORM_FIELDS.email, e.target.value.trim());
                      }}
                      ref={emailRef}
                      onBlur={handleBlur}
                      value={values.Email}
                      InputProps={{
                        style: {
                          color: newThemedTextColor,
                          backgroundColor: newThemedBackgroundColor,
                          borderRadius: '10px',
                        },
                        classes: { notchedOutline: classNames.noBorder },
                      }}
                    />
                    {errors && errors[ACCOUNT_FORM_FIELDS.email] && touched[ACCOUNT_FORM_FIELDS.email] && (
                      <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                        {errors[ACCOUNT_FORM_FIELDS.email]}
                      </StyledError>
                    )}
                  </Grid>
                )}
                {getIsGuestUserFlow() && !isPackage && (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '11px' }}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          className={classNames.inputField}
                          name={ACCOUNT_FORM_FIELDS.email}
                          placeholder={!getIsGuestUserFlow() ? 'Enter email' : 'Email'}
                          variant="outlined"
                          type="text"
                          onChange={e => {
                            setFieldValue(ACCOUNT_FORM_FIELDS.email, e.target.value.trim());
                          }}
                          ref={emailRef}
                          onBlur={handleBlur}
                          value={values.Email}
                          InputProps={{
                            style: {
                              color: newThemedTextColor,
                              backgroundColor: newThemedBackgroundColor,
                              borderRadius: '10px',
                              height: '48px',
                            },
                            classes: { notchedOutline: classNames.noBorder },
                          }}
                        />
                        {errors && errors[ACCOUNT_FORM_FIELDS.email] && touched[ACCOUNT_FORM_FIELDS.email] && (
                          <StyledError PrimaryColor={'red'} template={activeTemplate}>
                            {errors[ACCOUNT_FORM_FIELDS.email]}
                          </StyledError>
                        )}
                      </Grid>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '1rem',
                          flexWrap: 'wrap',
                          maxWidth: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Grid item xs={12} style={!mobileView ? { maxWidth: 'calc(50% - 0.5rem)' } : {}}>
                          <TextField
                            fullWidth
                            className={classNames.inputField}
                            name={ACCOUNT_FORM_FIELDS.firstName}
                            placeholder="First Name"
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.firstName}
                            type="text"
                            InputProps={{
                              style: {
                                color: newThemedTextColor,
                                backgroundColor: newThemedBackgroundColor,
                                borderRadius: '10px',
                                height: '48px',
                              },
                              classes: { notchedOutline: classNames.noBorder },
                            }}
                          />
                          {errors && errors[ACCOUNT_FORM_FIELDS.firstName] && touched[ACCOUNT_FORM_FIELDS.firstName] && (
                            <StyledError PrimaryColor={'red'} template={activeTemplate}>
                              {errors[ACCOUNT_FORM_FIELDS.firstName]}
                            </StyledError>
                          )}
                        </Grid>
                        <Grid item xs={12} style={!mobileView ? { maxWidth: 'calc(50% - 0.5rem)' } : {}}>
                          <TextField
                            fullWidth
                            className={classNames.inputField}
                            name={ACCOUNT_FORM_FIELDS.lastName}
                            placeholder="Last Name"
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.lastName}
                            type="text"
                            InputProps={{
                              style: {
                                color: newThemedTextColor,
                                backgroundColor: newThemedBackgroundColor,
                                borderRadius: '10px',
                                height: '48px',
                              },
                              classes: { notchedOutline: classNames.noBorder },
                            }}
                          />
                          {errors && errors[ACCOUNT_FORM_FIELDS.lastName] && touched[ACCOUNT_FORM_FIELDS.lastName] && (
                            <StyledError PrimaryColor={'red'} template={activeTemplate}>
                              {errors[ACCOUNT_FORM_FIELDS.lastName]}
                            </StyledError>
                          )}
                        </Grid>
                      </div>
                      <Grid item xs={12}>
                        <HelperTextField
                          isDarkModeEnabled={contribution.isDarkModeEnabled}
                          fullWidth
                          className={classNames.inputField}
                          name={ACCOUNT_FORM_FIELDS.details}
                          placeholder="Please share anything that will help prepare for our meeting"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.details}
                          type="text"
                          multiline
                          inputProps={{ maxLength: 500 }}
                          helperText={`${values?.details.length}/500`}
                          maxRows={6}
                          InputProps={{
                            style: {
                              color: newThemedTextColor,
                              backgroundColor: newThemedBackgroundColor,
                              borderRadius: '10px',
                              height: '9rem',
                              alignItems: 'flex-start',
                            },
                            classes: { notchedOutline: classNames.noBorder },
                          }}
                          style={{
                            maring: 0,
                          }}
                        />
                        {errors && errors[ACCOUNT_FORM_FIELDS.details] && touched[ACCOUNT_FORM_FIELDS.details] && (
                          <StyledError PrimaryColor={'red'} template={activeTemplate}>
                            {errors[ACCOUNT_FORM_FIELDS.details]}
                          </StyledError>
                        )}
                      </Grid>
                    </div>
                  </>
                )}
              </>
            )}
            {isShowInput(step, ACCOUNT_FORM_FIELDS.confirmEmail) && (
              <Grid item xs={12}>
                {/* <Input
                fullWidth
                name={ACCOUNT_FORM_FIELDS.confirmEmail}
                type="email"
                label="Confirm email"
                onChange={handleChange}
                error={touched.ConfirmEmail && errors.ConfirmEmail}
                ref={confirmEmailRef}
              /> */}
                <TextField
                  inputRef={textFieldRef}
                  fullWidth
                  className={classNames.inputField}
                  name={ACCOUNT_FORM_FIELDS.confirmEmail}
                  placeholder="Confirm Email "
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onPaste={handlePaste}
                  ref={confirmEmailRef}
                  type="email"
                  value={values?.confirmEmail}
                  InputProps={{
                    style: {
                      color: newThemedTextColor,
                      backgroundColor: newThemedBackgroundColor,
                      borderRadius: '10px',
                    },
                    classes: { notchedOutline: classNames.noBorder },
                  }}
                />
                {errors && errors[ACCOUNT_FORM_FIELDS.confirmEmail] && touched[ACCOUNT_FORM_FIELDS.confirmEmail] && (
                  <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                    {errors[ACCOUNT_FORM_FIELDS.confirmEmail]}
                  </StyledError>
                )}
              </Grid>
            )}
            {isShowInput(step, ACCOUNT_FORM_FIELDS.password) && (
              <Grid item xs={12}>
                {/* <Input
                fullWidth
                name={ACCOUNT_FORM_FIELDS.password}
                type="password"
                label={step === 'create' ? 'Create a Password' : 'Password'}
                onChange={handleChange}
                error={touched.Password && errors.Password}
              /> */}
                {(step === PURCHASE_MODAL_STEPS.login || step === PURCHASE_MODAL_STEPS.joinLogin) && (
                  <StyledEmail>
                    <span>
                      continue as <span style={{ color: colorToUse?.PrimaryColorCode }}>{EmailVal}</span>
                    </span>
                    <StyledRemoveBtnContainer
                      onClick={() => {
                        setStepValue(PURCHASE_MODAL_STEPS.init);
                      }}
                    >
                      x
                    </StyledRemoveBtnContainer>
                  </StyledEmail>
                )}
                <TextField
                  inputRef={textFieldRef}
                  fullWidth
                  className={classNames.inputField}
                  name={ACCOUNT_FORM_FIELDS.password}
                  placeholder={step === 'create' ? 'Create a Password' : 'Password'}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="password"
                  value={values?.password}
                  InputProps={{
                    style: {
                      color: newThemedTextColor,
                      backgroundColor: newThemedBackgroundColor,
                      borderRadius: '10px',
                    },
                    classes: { notchedOutline: classNames.noBorder },
                  }}
                />
                {errors && errors[ACCOUNT_FORM_FIELDS.password] && touched[ACCOUNT_FORM_FIELDS.password] && (
                  <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                    {errors[ACCOUNT_FORM_FIELDS.password]}
                  </StyledError>
                )}
              </Grid>
            )}
          </Grid>
          {isShowInput(step, RESTORE_PASS) && (
            <Grid item xs={12} style={{}}>
              <SendOtpLinkContainer>
                <StyledEmail isDarkModeEnabled={contribution.isDarkModeEnabled} onClick={handleShowOtpPopup}>
                  {' '}
                  Sign in with email one-time code
                </StyledEmail>
                <StyledEmail
                  style={{ color: newThemedTextColor }}
                  onClick={() => redirectToAppIfIsCoachDomain(domain, '/auth/restore-pass')}
                >
                  {' '}
                  Forgot Password?
                </StyledEmail>
              </SendOtpLinkContainer>
            </Grid>
          )}
          {error && (
            <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
              {error.message}
            </StyledError>
          )}
          <Grid
            item
            container
            // spacing={1}
            sm={12}
            xs={12}
            style={isCodeToJoinNotNull ? { display: 'flex', justifyContent: 'center' } : {}}
          >
            {isShowInput(step, ACCOUNT_FORM_FIELDS.firstName) && (
              <Grid item xs={12}>
                {/* <Input
                fullWidth
                name={ACCOUNT_FORM_FIELDS.firstName}
                type="text"
                label="First name"
                onChange={handleChange}
                error={touched.FirstName && errors.FirstName}
              /> */}
                <TextField
                  fullWidth
                  className={classNames.inputField}
                  name={ACCOUNT_FORM_FIELDS.firstName}
                  placeholder="First Name"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.firstName}
                  type="text"
                  InputProps={{
                    style: {
                      color: newThemedTextColor,
                      backgroundColor: newThemedBackgroundColor,
                      borderRadius: '10px',
                    },
                    classes: { notchedOutline: classNames.noBorder },
                  }}
                />
                {errors && errors[ACCOUNT_FORM_FIELDS.firstName] && touched[ACCOUNT_FORM_FIELDS.firstName] && (
                  <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                    {errors[ACCOUNT_FORM_FIELDS.firstName]}
                  </StyledError>
                )}
              </Grid>
            )}
            {isShowInput(step, ACCOUNT_FORM_FIELDS.lastName) && (
              <Grid
                item
                xs={12}
                // className={selectStyle.handleNewFormFieldsLastName}
              >
                {/* <Input
                fullWidth
                name={ACCOUNT_FORM_FIELDS.lastName}
                type="text"
                label="Last name"
                onChange={handleChange}
                error={touched.LastName && errors.LastName}
              /> */}
                <TextField
                  fullWidth
                  className={classNames.inputField}
                  name={ACCOUNT_FORM_FIELDS.lastName}
                  placeholder="Last Name"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.lastName}
                  type="text"
                  InputProps={{
                    style: {
                      color: newThemedTextColor,
                      backgroundColor: newThemedBackgroundColor,
                      borderRadius: '10px',
                    },
                    classes: { notchedOutline: classNames.noBorder },
                  }}
                />
                {errors && errors[ACCOUNT_FORM_FIELDS.lastName] && touched[ACCOUNT_FORM_FIELDS.lastName] && (
                  <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                    {errors[ACCOUNT_FORM_FIELDS.lastName]}
                  </StyledError>
                )}
              </Grid>
            )}
            {(isShowInput(step, ACCOUNT_FORM_FIELDS.sessionReminder) ||
              step == PURCHASE_MODAL_STEPS.loggedIn ||
              step == PURCHASE_MODAL_STEPS.login) &&
              showPhoneNumberFields === true && (
                <Grid
                  item
                  alignItems="flex-start"
                  md={12}
                  xs={12}
                  style={{
                    padding: '5px 0px',
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    style={{ color: newThemedTextColor }}
                    id="create-contribution-agreement"
                    color="primary"
                    checked={smsReminderCheckBox}
                    onChange={() => {
                      if (smsReminderCheckBox === true) {
                        setSmsReminderCheckBox(false);
                      } else {
                        setSmsReminderCheckBox(true);
                      }
                    }}
                  />
                  <div style={{ fontFamily: 'Avenir', fontWeight: '300', fontSize: '14px' }}>
                    Receive SMS session reminders
                  </div>
                </Grid>
              )}
            {(isShowInput(step, ACCOUNT_FORM_FIELDS.phone) ||
              isShowInput(step, ACCOUNT_FORM_FIELDS.sessionReminder) ||
              step == PURCHASE_MODAL_STEPS.loggedIn ||
              step == PURCHASE_MODAL_STEPS.login) &&
              smsReminderCheckBox === true &&
              showPhoneNumberFields === true && (
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  md={12}
                  xs={12}
                  style={{ padding: '5px 0px' }}
                >
                  <div style={{ width: '100%' }} ref={phoneInputRef}>
                    {defaultCountry && (
                      <PhoneInput
                        key={coachPhoneNumberFocused}
                        value={coachPhoneNumber}
                        defaultCountry={defaultCountry}
                        {...(!coachPhoneNumberFocused && { disableDialCodePrefill: true })}
                        {...(coachPhoneNumberFocused && { forceDialCode: true })}
                        disableCountryGuess
                        placeholder="Phone Number"
                        onChange={phone => {
                          setFieldValue(ACCOUNT_FORM_FIELDS?.phone, phone);
                          setPhoneNumberField(phone);
                          setCoachPhoneNumber(phone);
                        }}
                      />
                    )}
                    {errors && errors[ACCOUNT_FORM_FIELDS?.phone] && touched[ACCOUNT_FORM_FIELDS?.phone] && (
                      <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                        {errors[ACCOUNT_FORM_FIELDS?.phone]}
                      </StyledError>
                    )}
                    {showErrorPhoneNumber && (
                      <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                        Please add a valid phone number
                      </StyledError>
                    )}
                  </div>
                </Grid>
              )}
            {contribution.paymentType === 'Simple' &&
              contribution.taxType != 'No' &&
              isInviteToJoin === null &&
              isShowInput(step, ACCOUNT_FORM_FIELDS.country) &&
              !isMonthlySessionSubscription && (
                <>
                  <Grid
                    container
                    item
                    direction="column"
                    alignItems="flex-start"
                    md={12}
                    xs={12}
                    style={{
                      padding: '5px 5px',
                      position: 'relative',
                    }}
                  >
                    <Typography variant="h6" style={{ fontSize: '20px', color: newThemedTextColor }}>
                      Where are you located?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={selectStyle.handleNewFormFieldsTimeZone}>
                    <Select
                      label="Country of Residence"
                      name={ACCOUNT_FORM_FIELDS.country}
                      fullWidth
                      required
                      native
                      labelId="country"
                      value={formRef?.current?.values?.countryId}
                      className={[classNames.select, classNames.themedTextField].join(' ')}
                      onChange={e => {
                        const filteredObjects = states.filter(obj => obj.countryId === e.target.value);
                        const filteredCountry = countries.find(obj => obj.id === e.target.value);
                        const filteredTimezone = timeZones.filter(obj => obj.countryId === e.target.value);
                        if (filteredObjects.length >= 1) {
                          setStatesArray(filteredObjects);
                        } else if (filteredObjects.length === 0) {
                          setStatesArray(filteredObjects);
                          if (filteredTimezone.length === 0) {
                            setTimeZoneArray(timeZones);
                          } else if (
                            filteredTimezone.length === 1 &&
                            filteredCountry.id != '60b8ddb57205057e7ce2b861'
                          ) {
                            setTimeZoneArray(filteredTimezone);
                            setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredTimezone[0].countryName);
                          } else {
                            setTimeZoneArray(filteredTimezone);
                          }
                        }
                        if (filteredCountry.id === '60b8ddb57205057e7ce2b861') {
                          setTimeZoneArray(filteredTimezone);
                        }
                        setFieldValue(ACCOUNT_FORM_FIELDS.state, '');
                        setFieldValue(ACCOUNT_FORM_FIELDS.country, filteredCountry.id);
                        setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
                      }}
                      disableUnderline
                      style={{
                        padding: '15px',
                        position: 'relative',
                        backgroundColor: newThemedBackgroundColor,
                        color: newThemedTextColor,
                        borderRadius: '10px',
                      }}
                    >
                      <option aria-label="Time Zone" value={null}>
                        Select Country
                      </option>
                      {countries?.length > 0 &&
                        countries.map(timeZone => <option value={timeZone.id}>{timeZone.name}</option>)}
                    </Select>
                  </Grid>
                </>
              )}
            {contribution.taxType != 'No' &&
              isInviteToJoin === null &&
              formRef?.current?.values?.countryId === '60b8ddb57205057e7ce2b861' &&
              statesArray.length > 1 &&
              !isMonthlySessionSubscription && (
                <Grid item xs={12} className={selectStyle.handleNewFormFieldsTimeZone}>
                  <Select
                    label="State"
                    name={ACCOUNT_FORM_FIELDS.state}
                    fullWidth
                    required
                    native
                    labelId="stateCode"
                    style={{
                      padding: '15px',
                      position: 'relative',
                      backgroundColor: newThemedBackgroundColor,
                      color: newThemedTextColor,
                      borderRadius: '10px',
                    }}
                    value={formRef?.current?.values?.stateCode}
                    className={[classNames.select, classNames.themedTextField].join(' ')}
                    onChange={e => {
                      const filteredState = states.find(obj => obj.alpha2Code === e.target.value);
                      const filteredObjects = timeZones.filter(obj => obj.statesIds.includes(filteredState.id));
                      if (filteredObjects.length >= 1) {
                        if (filteredObjects.length === 1) {
                          setTimeZoneArray(filteredObjects);
                          setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredObjects[0].countryName);
                        } else {
                          setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredObjects[0].countryName);
                          setTimeZoneArray(filteredObjects);
                        }
                      } else {
                        setTimeZoneArray(timeZones);
                      }
                      setFieldValue(ACCOUNT_FORM_FIELDS.state, filteredState.alpha2Code);
                    }}
                    disableUnderline
                  >
                    <option aria-label="Time Zone" value={null}>
                      Select State
                    </option>
                    {statesArray?.length > 0 &&
                      statesArray.map(timeZone => <option value={timeZone.alpha2Code}>{timeZone.name}</option>)}
                  </Select>
                </Grid>
              )}
            {contribution.paymentType === 'Simple' &&
              contribution.taxType != 'No' &&
              isShowInput(step, ACCOUNT_FORM_FIELDS.country) &&
              isInviteToJoin === null &&
              timeZoneArray.length > 1 &&
              formRef?.current?.values?.countryId != null && (
                <>
                  <Grid item xs={12} className={selectStyle.handleNewFormFieldsTimeZone}>
                    <Select
                      required
                      native
                      labelId="timezones"
                      name={ACCOUNT_FORM_FIELDS.timeZoneId}
                      onChange={e => {
                        setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, e.target.value);
                        const color = e.target.value === 'Select Timezone' ? 'darkgrey' : 'black';
                        setColorForSelect(color);
                      }}
                      value={formRef?.current?.values?.TimeZoneId}
                      onBlur={handleBlur}
                      disableUnderline
                      className={[classNames.select, classNames.themedTextField].join(' ')}
                      style={{
                        padding: '15px',
                        position: 'relative',
                        backgroundColor: newThemedBackgroundColor,
                        color: newThemedTextColor,
                        borderRadius: '10px',
                      }}
                    >
                      <option aria-label="Time Zone" value={null}>
                        Select Timezone
                      </option>
                      {timeZoneArray?.length > 0 &&
                        timeZoneArray.map(timeZone => (
                          <option
                            value={timeZone.countryName}
                            style={{ backgroundColor: newThemedBackgroundColor, color: newThemedTextColor }}
                          >
                            {timeZone.name}
                          </option>
                        ))}
                    </Select>
                    {errors && errors[ACCOUNT_FORM_FIELDS.timeZoneId] && touched[ACCOUNT_FORM_FIELDS.timeZoneId] && (
                      <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                        {errors[ACCOUNT_FORM_FIELDS.timeZoneId]}
                      </StyledError>
                    )}
                  </Grid>
                </>
              )}
            {((contribution.paymentType === 'Simple' && contribution.taxType === 'No') ||
              isMonthlySessionSubscription ||
              contribution.paymentType === 'Advance' ||
              isInviteToJoin != null) &&
              isShowInput(step, ACCOUNT_FORM_FIELDS.timeZoneId) &&
              timeZoneArray.length > 1 &&
              step != 'login' &&
              step != 'joinLogin' && (
                <>
                  <Grid
                    container
                    item
                    direction="column"
                    alignItems="flex-start"
                    md={12}
                    xs={12}
                    style={{
                      padding: '5px 5px',
                      position: 'relative',
                    }}
                  >
                    <Typography variant="h6" style={{ fontSize: '20px', color: newThemedTextColor }}>
                      Save Your Time Zone Preference
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={selectStyle.handleNewFormFieldsTimeZone}>
                    <Select
                      required
                      native
                      labelId="timezones"
                      className={[classNames.select, classNames.themedTextField].join(' ')}
                      name={ACCOUNT_FORM_FIELDS.timeZoneId}
                      onChange={e => {
                        setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, e.target.value);
                        const color = e.target.value === 'Select Timezone' ? 'darkgrey' : 'black';
                        setColorForSelect(color);
                      }}
                      value={formRef?.current?.values?.TimeZoneId}
                      onBlur={handleBlur}
                      disableUnderline
                      style={{
                        padding: '15px',
                        position: 'relative',
                        backgroundColor: newThemedBackgroundColor,
                        color: newThemedTextColor,
                        borderRadius: '10px',
                      }}
                    >
                      <option aria-label="Time Zone" value={null}>
                        Select Timezone
                      </option>
                      {timeZoneArray?.length > 0 &&
                        timeZoneArray.map(timeZone => (
                          <option
                            value={timeZone.countryName}
                            style={{ backgroundColor: newThemedBackgroundColor, color: newThemedTextColor }}
                          >
                            {timeZone.name}
                          </option>
                        ))}
                    </Select>
                    {errors && errors[ACCOUNT_FORM_FIELDS.timeZoneId] && touched[ACCOUNT_FORM_FIELDS.timeZoneId] && (
                      <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                        {errors[ACCOUNT_FORM_FIELDS.timeZoneId]}
                      </StyledError>
                    )}
                  </Grid>
                </>
              )}
          </Grid>
        </Grid>
      )}
      {isElectronicSignatureActive &&
        !contribution?.customWaiverId &&
        isShowInput(step, ACCOUNT_FORM_FIELDS.signature) && (
          <Grid item xs={12}>
            <div style={{ position: 'relative' }}>
              <SignatureCanvas
                ref={canvasRef}
                clearOnResize={false}
                penColor="black"
                canvasProps={{ height: '300px', className: classNames.inputField }}
                onEnd={() => {
                  // setDisableBtn(false);
                  setDisableValue(false);
                  if (canvasRef?.current) {
                    const val = canvasRef?.current?.getTrimmedCanvas().toDataURL('image/png');
                    setFieldValue(ACCOUNT_FORM_FIELDS.signature, val);
                  }
                }}
              />
              <div
                type="button"
                onClick={clearSign}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '5px',
                  right: '10px',
                  // color: PrimaryColor,
                  fontWeight: 'bold',
                }}
              >
                Clear
              </div>
            </div>
            <p
              style={{
                marginBottom: '0px',
                fontSize: '12px',
                marginLeft: '5px',
                color: PrimaryColor,
              }}
            >
              Sign Here To Join
            </p>
          </Grid>
        )}
      {showOtpModal && (
        <SignInWithOtpModal
          isOpen={showOtpModal}
          email={values.Email}
          isDarkModeEnabled={contribution.isDarkModeEnabled}
          formRef={formRef}
          onClose={closeModal}
          accentColorCode={colorToUse?.AccentColorCode}
          primaryColor={PrimaryColor}
          textColor={newThemedTextColor}
          activeTemplate={activeTemplate}
          errorMsg={error?.message}
        />
      )}
    </Grid>
  );
}

CustomAccountForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
  step: PropTypes.string.isRequired,
  timeZoneId: PropTypes.string,
  timeZone: PropTypes.shape({
    timeZones: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  timeZoneLoading: PropTypes.bool.isRequired,
  fetchTimeZones: PropTypes.func.isRequired,
  typeOfPayment: PropTypes.string.isRequired,
};

const mapStateToProps = ({ timeZone }) => ({
  timeZones: timeZone?.timeZones,
  timeZoneLoading: timeZone?.loading,
});

const actions = {
  fetchTimeZones: timeZoneActions.fetchTimeZones,
};

export default connect(mapStateToProps, actions)(CustomAccountForm);
