import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import * as R from 'ramda';
import { useTheme, useMediaQuery } from '@material-ui/core';
import instagramIcon from 'assets/instagram.svg';
import linkedinIcon from 'assets/linkedin.svg';
import youtubeIcon from 'assets/youtube.svg';
import webIcon from 'assets/web.svg';
import facebookIcon from 'assets/facebook.svg';
import tiktokIcon from 'assets/tiktok29.svg';
import { TextField } from '@material-ui/core';
import { getThemedColors } from 'services/contributions.service';

import {
  TestimonialParagraph,
  TestimonialStarts,
  CardTopHeadings,
  PageTitle1,
  StyledPricTxt,
  PackegeFeeMainContainer,
  PackegeLeftText,
  PackegeRightText,
  EnrolDateTxt,
  MonthDate,
  DividerDiv,
  CompleteRegistrationTxt,
  DetailsMainContainer,
  DetailSideIconDiv,
  DetailSideIcon,
  Temp2MainContainer,
  MainImgDiv,
} from '../AboutMainInfoBlock/AboutMainInfo.styles';
import CustomPurchaseBlock from '../PurchaseBlock/CustomPurchaseBlock';
import '../AboutMainInfoBlock/aboutStyle.css';
import TextBlock from '../TextBlock';
import {
  setCohealerInfoForClient,
  clearCohealerInfoForClient,
  setCohealerIcon,
  clearCohealerIcon,
} from 'actions/cohealerInfo';
import { useHttp, useShallowEqualSelector } from 'hooks';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond, LabelText } from 'components/UI/Text/TextStyles';
import { CoachItem } from './CoachItem';
import bannerImg from '../../../../assets/Main-banner-about.png';
import fbImg from '../../../../assets/facebook1.png';
import linkedImg from '../../../../assets/linkedin1.png';
import instaImg from '../../../../assets/instagram1.png';
import youtube from '../../../../assets/youtube1.png';
import tiktok from '../../../../assets/tiktok1.png';
import website from '../../../../assets/website1.png';
import breathIcon from '../../../../assets/icon-breathwork.png';
import heartIcon from '../../../../assets/heart-icon.png';
import { toRem } from 'utils/styles';
import { TotalCostBlock } from '../PaymentComponents';
import PurchaseBlock from '../PurchaseBlock/PurchaseBlock';
import EnrollmentBlock from '../EnrollmentBlock';
import AboutGeneralInfoBlock from '../AboutGeneralInfoBlockTemOne';
import { lightOrDark } from 'utils/utils';
import { ContributionType } from 'helpers/constants';

const Image = styled.img`
  background-color: #f5f5f5;
  width: 100%;
  max-height: 360px;
  object-fit: contain;
  display: block;
`;
const StyledLabelText = styled(LabelText)`
  font-weight: 500;
`;
const StyledImg = styled.img`
  margin-right: 5px;
  margin-bottom: 5px;
`;
const SocialGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  border-top: 1px solid #e7e7e7;
  margin-top: 20px !important;
`;
const TagsDiv = styled.div`
  margin: 0px 10px 0px 0px;
`;
// const MainImg = styled.image`
//   background-image: url(${MainImgBanner});
//   padding: 18rem;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
// `;
const StyledCardBody = styled(CardBody)`
  padding: 16px;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f7f9f9;
  ${({ mobileView }) => mobileView && `padding: 8px;`}
`;
const PageTitleSecond2 = styled.h1`
  font-size: ${toRem(14)};
  font-weight: 420;
  line-height: normal;
  letter-spacing: 0.25px;
  color: #6999ca;
  color: ${props => (props ? props.Color : '#6999CA')};
  &:hover {
    cursor: pointer;
  }
  // margin: 10px;
  ${({ mobileView }) =>
    mobileView &&
    `
    font-size: ${toRem(18)};
    margin: 0px;
  `};
`;

const StyledHeaderTempOne = styled.div`
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  padding: 0 24px;
  justify-content: space-between;
  background: #e9f0f7;
  border-radius: 5px 5px 0px 0px;
  background: #e9f0f7;
  border-radius: 5px 5px 0px 0px;
  ${({ mobileView }) => mobileView && `padding: 16px;`}
`;

const StyledBannerImg = styled.div`
  background-image: url(${({ bannerImg }) => bannerImg});
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 20%;
  padding-bottom: 20%;
  border-radius: 10px;
  ${({ bannerImg }) => bannerImg[0] === null && `background-color: #f8f8f8;`}
  ${({ mobileView }) => mobileView && `height: 160px`}
  ${({ isPurchased }) =>
    isPurchased &&
    `padding-top: 12%;
     padding-bottom:12%;
     background-position: center;
  `}
`;

const SocialLinkMainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0px 1rem 0px;
  ${({ mobileView }) => mobileView && `padding: 1rem 0px 1rem 0px;`}
`;
const LeftBtnMain = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const TagsBtn1 = styled.button`
  background-color: '#CDBA8F';
  border-radius: 5px;
  border: 0px;
  font-size: 12px;
  padding: 5px 10px;
  color: #fff;
  font-family: 'Brandon Text';
`;
const TagsBtn2 = styled.button`
  background: #ce6467;
  border-radius: 5px;
  border: 0px;
  font-size: 12px;
  padding: 5px 10px;
  color: #fff;
  font-family: 'Brandon Text';
`;
const TagsBtn3 = styled.button`
  background: #f0f3f3;
  border-radius: 5px;
  border: 0px;
  font-size: 12px;
  padding: 5px 10px;
  color: #000;
  font-family: 'Brandon Text';
`;
const RightLinks = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 30%;
`;
const FbImg = styled.img``;
const LinkImg = styled.img``;
const InstaImg = styled.img``;
const MainHeading = styled.div``;
const StyledTitle = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: clamp(25px, 4vw, 44px);
  letter-spacing: 0.02em;
  line-height: 1.5;
  break-word: word-break;
  color: #282b2b;
  margin: 0px;
  ${({ showPaymentCard }) => (showPaymentCard ? `padding: 10px;` : `padding:0px`)}
`;
const StyledTagline = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #656d6d;
  padding: 0.5rem 0px;
  line-height: 1.5;
  break-word: word-break;
  width: 779px;
  ${({ showPaymentCard }) => !showPaymentCard && `  padding: 0.5rem 0px;`};
  ${({ showPaymentCard }) => showPaymentCard && `padding: 0.5rem 10px;`}
  hzeight: 30px;
`;
const MainHeaderPackegs = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background: #6999ca;
  padding: 14px 0px;
`;
const SimpleSesstionP = styled.p`
  margin: 0px;
`;
const PackegeP = styled.p`
  margin: 0px;
`;
const SubscriptionP = styled.p`
  margin: 0px;
`;
export const StyledButton = styled.button`
  position: relative;
  padding: '0px 10px 0px 10px';
  font-size: 0.6rem;
  border-radius: 5px;
  border: none;
  color: ${props => props.color};
  fill: #fff;
  font-weight: 700;
  cursor: default;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: 1.25px;
  min-width: 102px;
  height: 23px;
  transition: background 0.2s;
  font-family: 'Poppins';
  background: ${props => props.background};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15.6px;
  margin: ${props => props.margin};
  max-width: ${props => props.maxWidth};
`;

const AboutMainInfoBlock = ({
  contribution,
  paymentInfo,
  id,
  userId,
  previewContentUrls,
  serviceProviderName,
  contributionPartners,
  socialUrls: { instagramUrl, linkedInUrl, youtubeUrl, facebookUrl, websiteUrl, tiktokUrl },
  categories,
  title,
  isCoach,
  isPurchased,
  gender,
  minAge,
  languageCodes,
  contributionTagline,
  setShouldRedirect,
  isCustomBrandingColorsActive,
  brandingColors,
  type,
}) => {
  const { enrollment } = contribution;
  const dispatch = useDispatch();
  const { request } = useHttp();
  const avatarUrl = useShallowEqualSelector(state => state?.cohealerInfo?.avatarUrl);

  const { themedColor, newThemedTextColor } = getThemedColors(contribution, isCoach);

  useEffect(() => {
    request(`/Contribution/GetCohealerInfoForClient/${userId}`, 'GET')
      .then(R.compose(dispatch, setCohealerInfoForClient))
      .catch(console.dir);
    return R.compose(dispatch, clearCohealerInfoForClient);
  }, [userId, dispatch, request]);

  useEffect(() => {
    request(`/User/GetCohealerIcon/${id}`, 'GET').then(R.compose(dispatch, setCohealerIcon)).catch(console.dir);

    return R.compose(dispatch, clearCohealerIcon);
  }, [id, dispatch, request]);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const showPaymentCard = useMediaQuery(theme.breakpoints.down('sm'));

  const socialLinks = [
    {
      title: 'Website',
      url: websiteUrl,
      icon: webIcon,
    },
    {
      title: 'Instagram',
      url: instagramUrl,
      icon: instagramIcon,
    },
    {
      title: 'LinkedIn',
      url: linkedInUrl,
      icon: linkedinIcon,
    },
    {
      title: 'Facebook',
      url: facebookUrl,
      icon: facebookIcon,
    },
    {
      title: 'Youtube',
      url: youtubeUrl,
      icon: youtubeIcon,
    },
    {
      title: 'Tiktok',
      url: tiktokUrl,
      icon: tiktokIcon,
    },
  ];
  const isAnyLinkExist = !!socialLinks.filter(sl => !!sl.url).length;

  const colors = ['#CDBA8F', '#CE6467', '#F0F3F3'];

  // const colours = [Math.floor(Math.random() * colors.length)];
  const [singleSession, setsingleSession] = useState(false);
  const [packegeClick, setpackegeClick] = useState(true);
  const [subscription, setsubscription] = useState(false);
  const [summary, setSummary] = useState({});
  const HyperLink = styled.a.attrs({ target: '_blank' })``;
  const CardInner = styled.div``;
  const LeftDiv = styled.div`
    width: 100%;
    ${({ mobileView }) =>
      mobileView &&
      `
    display: flex;
    justify-content: center;
    flex-wrap: wrap;`}
  `;
  var colorsStyle2 = [
    brandingColors.PrimaryColorCode,
    brandingColors.AccentColorCode,
    brandingColors.TertiaryColorCode,
  ];

  const style = {
    marginTop: '36px',
    border: '1px solid #DBE7F2',
    borderRadius: '16px',
    padding: showPaymentCard ? '0px' : '32px',
  };

  const categoriesBtns = () => {
    const Btns = categories?.map((item, index) => {
      var color2 = colorsStyle2[index % colorsStyle2.length];
      return (
        <StyledButton
          disabled={true}
          mobileView={mobileView}
          background={color2}
          color={lightOrDark(color2)}
          margin="5px 3px"
          style={{ fontFamily: 'Brandon Text', fontStyle: 'normal', fontWeight: '400', fontSize: '12px' }}
        >
          {item}
        </StyledButton>
      );
    });

    return Btns;
  };

  return (
    <Grid container style={style}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {JSON.stringify(previewContentUrls).includes('coherepublic') && (
          <img src={previewContentUrls} className={'templateOneImage'} alt={title} />
        )}
        {!JSON.stringify(previewContentUrls).includes('coherepublic') && (
          <div className="noImageBackground" style={{ background: 'rgb(250 250 250)', height: 400 }} />
        )}
      </Grid>
      <Grid item xs={12}>
        {/* <SocialLinkMainContainer mobileView={mobileView}>
          <LeftDiv mobileView={mobileView}>
            {categories.map((item, index) => {
              var color2 = colorsStyle2[index % colorsStyle2.length];
              return (
                <StyledButton
                  disabled={true}
                  mobileView={mobileView}
                  background={color2}
                  color={lightOrDark(color2)}
                  margin="5px 3px"
                  style={{ fontFamily: 'Brandon Text', fontStyle: 'normal', fontWeight: '400', fontSize: '12px' }}
                >
                  {item}
                </StyledButton>
              );
            })}
          </LeftDiv>
        </SocialLinkMainContainer> */}
        <MainHeading className="templateOneHeading">
          <StyledTitle
            className="templateOnePadding"
            style={{ color: themedColor, padding: showPaymentCard ? '0px 10px' : '0.5rem 0px' }}
          >
            {title}
          </StyledTitle>
          {contributionTagline && (
            <StyledTagline
              className="templateOnePadding"
              style={{ color: '#656D6D', width: '100%', height: '100%', padding: showPaymentCard ? '0px 10px' : '0px' }}
            >
              {contributionTagline}
            </StyledTagline>
          )}
          <AboutGeneralInfoBlock
            style={{ color: newThemedTextColor }}
            contribution={contribution}
            categories={categories}
            gender={gender}
            minAge={minAge}
            languageCodes={languageCodes}
            brandingColors={brandingColors}
          />
        </MainHeading>
      </Grid>
    </Grid>
  );
};

AboutMainInfoBlock.propTypes = {
  id: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  previewContentUrls: PropTypes.arrayOf(PropTypes.string),
  serviceProviderName: PropTypes.string,
  contributionPartners: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string,
      avatarUrl: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  ).isRequired,
  socialUrls: PropTypes.shape({
    instagramUrl: PropTypes.string,
    linkedInUrl: PropTypes.string,
    youtubeUrl: PropTypes.string,
    facebookUrl: PropTypes.string,
    websiteUrl: PropTypes.string,
  }),
};

AboutMainInfoBlock.defaultProps = {
  previewContentUrls: [],
  serviceProviderName: 'No name',
  socialUrls: {},
};

export default AboutMainInfoBlock;
